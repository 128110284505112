<template>
  <div class="support">
    <div class="support__wrapper">
      <div class="support__title">{{ $t("pages.support.title") }}</div>
      <Card class="support__card">
        <div class="support__entry">
          <div class="support__entry-title">
            {{ $t("pages.support.phone") }}
          </div>
          <p class="support__entry-text">
            +49 40 22858947
          </p>
        </div>

        <div class="support__entry">
          <div class="support__entry-title">
            {{ $t("pages.support.email") }}
          </div>
          <p class="support__entry-text">
            support@lobbyspace.me
          </p>
        </div>

        <div class="support__entry">
          <iframe
            sandbox="allow-scripts allow-popups allow-forms allow-same-origin"
            width="100%"
            height="800px"
            style="border: 0; overflow-x: auto"
            src="https://forms.helpdesk.com?licenseID=1510242712&contactFormID=aeb8db4a-8551-4e5a-89b5-5b78305c6788"
          >
            Your browser does not allow embedded content.
          </iframe>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: {
    Card
  }
};
</script>

<style lang="scss">
.support {
  min-height: 100vh;
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto;

  &__title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }

  &__card {
    margin: 1rem 0;
  }

  &__entry {
    @apply mb-4;
  }

  &__entry-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  &__entry-text {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
}

@media screen and (min-width: 900px) {
  .support {
    padding: 36px 64px;
    display: flex;
    justify-content: center;

    &__wrapper {
      width: 745px;
    }
  }
}
</style>
